.slider {
  position: relative;
}

.slide {
  display: flex;
  width: 100%;
  margin-top: 5rem;
  position: relative;

  &-inner {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill !important;
    }

    .image {
      min-width: 100% !important;
    }
  }

  .clickScroll {
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%);
    width: 35px;
    height: 70px;
    border-radius: 50px;
    background-color: transparent;
    border: 3px solid red(75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 21;

    .c-icon {
      color: red(75);
    }
  }

  &-caption {
    max-width: 40%;
    min-width: 30%;
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    color: red(75);
    font-size: 48px;
    padding: 0 15px;

    &-text-white {
      color: light-neutral(0) !important;
    }

    &-title {}

    &-subtitle {
      font-weight: 700;
    }
  }
}

.sliderNav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 27px;
  left: 5%;
  z-index: 6;
  padding: 0 15px;
  cursor: pointer;

  .c-icon {
    color: dark-neutral(0) !important;
    font-weight: 800;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;

    button {
      cursor: pointer;
    }

    .dot {
      min-width: 20px !important;
      min-height: 20px !important;
      border-radius: 50%;
      border: 1px solid red(75);
      background-color: transparent;

      &-active {
        background-color: red(75);
      }
    }

    .arrow {
      padding: 5px;
      background-color: red(75);
      border-radius: 50%;
      border: none;
      outline: none;

      &:hover {
        background-color: light-neutral(0);

        span {
          background-color: light-neutral(0);
        }
      }
    }

  }
}

// max-width 1440px
@media #{$media-xl} {
  .slide {
    &-caption {
      font-size: 36px;
    }
  }
}

// max-width 1200px
@media #{$media-lg} {}

// max-width 1024px
@media #{$media-md} {
  .slide {
    &-caption {
      font-size: 30px;
    }
  }
}


// max-width 480px
@media #{$media-xs} {}

// max-width 360px
@media #{$media-es} {}

@media only screen and (max-width: 880px) {
  .sliderNav {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .slide {
    height: 500px;

    &-caption {
      font-size: 34px;
      left: 5%;
      top: 40%;
    }

    &-inner {
      img {
        height: 500px;
        object-fit: fill;
      }
    }
  }
}

// max-width 768px
@media #{$media-sm} {
  .slide {
    &-caption {
      max-width: 100%;
      font-size: 22px;
    }
  }
}