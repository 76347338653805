.accordion {
  &-item {
    width: 100%;
    margin: 10px 0;
    background: transparent;
    border: none;
    overflow: hidden;
    transition: all, 0.8s ease-out;
    line-height: auto;
    cursor: pointer;
    border-bottom: 1px solid #d1a998;

    &:last-child {
      border: none;
    }

    &-title-part {
      display: flex;
      justify-content: space-between;
      padding: 15px 45px 15px 15px;
      font-size: 15px;
      letter-spacing: 0.25px;
      font-weight: bold;
      align-items: center;
      text-align: left;
      position: relative;
      color: #734938;

      .c-icon {
        top: 30%;
        transition: all, 0.4s;
        position: absolute;
        right: 15px;
        color: #734938 !important;
        rotate: z 0deg
      }
    }

    &-content-part {
      text-align: left;

      max-height: 0;
        overflow: hidden;
        transition: all, .4s;
      &-in {
        padding: 15px 0;
      }

      .image {
        width: 200px;
        float: left;
        min-width: 150px;
        margin: 0 10px 10px 0;
      }
    }

    &--active {
      max-height: 5000px;
      transition: all, 4s;

      .accordion-item-title-part {
        background: red(75);
        color: #fff;
        transition: all, 0.4s;

        .c-icon {
          rotate: z 90deg
        }
      }
      .accordion-item-content-part {
        max-height: 5000px;
      }
    }
  }

  &--managment {
    .accordion-item {
      border-color: #d1a998;
      &-title {
        &-part {
          font-size: 40px;
          background: transparent;
          padding: 0;
          .title {
            color: #734938 !important;
            font-weight: 700;
            line-height: 55px;
            span {
              font-size: 20px;
              display: block;
              font-weight: 400;
              line-height: 27px;
            }
          }
          .c-icon {
            color: #734938;
          }
        }
      }
      &-content {
        &-part {
          .image {
            height: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  .accordion {
    &-item {
      &-title-part {}

      &-content-part {
        flex-direction: column;

        img {
          height: 250px;
        }
      }
    }
  }
}
