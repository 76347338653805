.bull-banner {
  width: 100%;
  height: 300px;
  padding: 0 3rem;
  margin-top: 3rem;
  &-inner {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1000px;
    margin: auto;
    img {
      z-index: 2;
      width: 400px;
      transform: rotateY(180deg);
    }
    .content {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 65%;
      transform: translate(-50%, -50%);
      background-color: red;
      width: 700px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: light-neutral(0);
      background-color: red(75);
      border-radius: 0 0 100px;
      .title {
        font-weight: 700;
        font-size: 50px;
      }
      .desc {
        max-width: 400px;
        font-size: 16px;
      }
    }
  }
}
@media only screen and (max-width: 860px) {
  .bull-banner {
    margin-bottom: 12rem;
    &-inner {
      .content {
        width: 100%;
        height: 400px;
        top: 130%;
        left: 50%;
        border-radius: 0;
      }
    }
  }
}
