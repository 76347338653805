.flip-cards {
  margin-top: 2rem;
  position: relative;
  width: 100%;
  min-height: 350px;
  overflow: hidden;

  &-container {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    align-items: center;

    .flip-card {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      overflow: hidden;
      height: 350px;
      width: 33%;
      &-inner {
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;

        &:hover {
          transform: rotateY(180deg);
        }

        .front,
        .back {
          position: absolute;
          width: 100%;
          height: 100%;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }

        img {
          width: 250px !important;
        }

        .back {
          transform: rotateY(180deg);
        }
      }
      .desc{
        padding: 0 1rem;
      }
    }
  }
}

@media only screen and (max-width: 860px){
  .flip-cards{
    &-container{
      flex-direction: column;
      text-align: center;
    }
  }
  .flip-card{
    width: 100% !important;
  }
}