.textandimage {
  padding: 4rem 0;

  .container {
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 5rem;

      .left-part {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-titles {
          color: red(75);
          font-size: 50px;
          font-weight: 300;

          .title {
            width: max-content;
            border-bottom: 1px solid red(75);
          }

          .subtitle {}
        }

        &-desc {
          font-size: 21px;
        }
      }

      .right-part {
        display: flex;
        justify-content: flex-end;

        .image {
          img {
            border-radius: 0 0 0 80px;
          }
        }
      }
    }
  }
}

// max-width 1440px
@media #{$media-xl} {}

// max-width 1200px
@media #{$media-lg} {}

// max-width 1024px
@media #{$media-md} {
  .textandimage {
    .container {
      .row {
        .left-part {
          &-titles {
            font-size: 30px;
          }

          &-desc {
            font-size: 17px;
          }
        }
      }
    }
  }
}

// max-width 768px
@media #{$media-sm} {
  .textandimage {
    .container {
      .row {
        flex-direction: column;

        .left-part {
          padding: 0 4rem;
        }

        .right-part {
          justify-content: center;
        }
      }
    }
  }
}

// max-width 480px
@media #{$media-xs} {
  .textandimage {
    .container {
      .row {
        .left-part {
          padding: 0;
        }
      }
    }
  }
}

// max-width 360px
@media #{$media-es} {}