@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Heavy.woff2') format('woff2'),
        url('/fonts/Avenir-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Black Oblique';
    src: url('/fonts/Avenir-BlackOblique.woff2') format('woff2'),
        url('/fonts/Avenir-BlackOblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Black.woff2') format('woff2'),
        url('/fonts/Avenir-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-HeavyOblique.woff2') format('woff2'),
        url('/fonts/Avenir-HeavyOblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Book';
    src: url('/fonts/Avenir-Book.woff2') format('woff2'),
        url('/fonts/Avenir-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Book';
    src: url('/fonts/Avenir-BookOblique.woff2') format('woff2'),
        url('/fonts/Avenir-BookOblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Medium.woff2') format('woff2'),
        url('/fonts/Avenir-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Light.woff2') format('woff2'),
        url('/fonts/Avenir-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-LightOblique.woff2') format('woff2'),
        url('/fonts/Avenir-LightOblique.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-MediumOblique.woff2') format('woff2'),
        url('/fonts/Avenir-MediumOblique.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Oblique.woff2') format('woff2'),
        url('/fonts/Avenir-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Roman.woff2') format('woff2'),
        url('/fonts/Avenir-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}