.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 17;
  width: 100%;
  background-color: #2e2e2e;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red(75);
  text-wrap: nowrap;

  a {
    &:hover {
      color: light-neutral(0);
      transition: all, 0.2s;
    }
  }

  a:focus,
  a:active {
    outline: none !important;
    border: none !important;
  }

  .lang-btn {
    background-color: transparent;
    border: none;
    color: red(75);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    font-weight: 700;
    cursor: pointer;
  }

  .c-icon {
    color: red(75);
  }

  ul>li {
    margin: 0;
    padding: 0;
  }

  &-logo-parent {
    position: relative;
    height: 80px;

    &-logo {
      top: -20%;
      z-index: 11;

      div {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        // min-height: 100px !important;
      }
    }
  }

  &-menu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    font-weight: 600;
    gap: 5px;

    .fourth {
      min-width: max-content;
      background-color: #2e2e2e;
      display: none;
      position: absolute;
      left: 100%;
      top: 5px;
      border-radius: 2px;
      list-style-type: none;
      text-align: left;
      padding: 0 15px;
      transition: all, 0.4s ease-in-out;
      z-index: 23;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    .third {
      min-width: max-content;
      background-color: #2e2e2e;
      display: none;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      position: absolute;
      left: 150px;
      top: 5px;
      border-radius: 2px;
      list-style-type: none;
      text-align: left;
      padding: 0 15px;
      transition: all, 0.4s ease-in-out;
      z-index: 22;

      >li {
        position: relative;

        &:hover {
          text-decoration: underline;

          .fourth {
            display: block;
            transition: all, 0.4s ease-in-out;
          }
        }
      }
    }

    .second {
      min-width: max-content;
      list-style-type: none;
      line-height: 2rem;
      display: none;
      background-color: #2e2e2e;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 15px;
      position: absolute;
      top: 15px;
      left: 0;
      border-radius: 2px;
      text-align: left;
      z-index: 21;

      >li {
        position: relative;

        &:hover {
          text-decoration: underline;

          .third {
            display: block;
            transition: all, 0.4s ease-in-out;
          }
        }
      }
    }

    .first {
      min-width: max-content;

      button {
        background-color: transparent;
        border: none;
        outline: none;
        color: red(75);
        font-weight: 500;
        position: relative;
        transition: all, 0.4s ease-in-out;
      }

      &:hover {
        .second {
          display: block;
          transition: all, 0.4s ease-in-out;
        }

        .c-icon {
          color: light-neutral(0);
          transform: rotate(90deg);
          transition: all, 0.2s ease-in-out;
        }
      }
    }
  }

  .menu-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.mobile-menu {
  color: red(75);
  position: fixed;
  top: -200%;
  left: -8px;
  z-index: 11;
  width: 103vw;
  height: max-content;
  min-height: 400px;
  background-color: #2e2e2e;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right !important;
  padding: 4rem 1rem;
  transition: all, 0.2s ease-in-out;
  font-size: 18px;

  &-show {
    transition: all, 0.2s ease-in-out;
    top: 7% !important;
  }

  ul {
    list-style-type: none !important;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .menuItem {

    >a:first-child,
    >div:first-child {
      font-weight: 700;
      font-size: 21px;
      background-color: transparent;
      outline: none;
      border: none;
      color: red(75);
      cursor: pointer;
    }

    .mobile-submenu {
      display: none;
      transition: all, 0.4s ease-in-out;

      &--show {
        display: block;

        >div {
          margin: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  .header {
    .lang-btn {
      padding: 0;
      margin-right: 1rem;
      font-size: 23px;
    }

    &-logo-parent {
      &-logo {
        left: 0;
      }
    }
  }
}