.bulls-contact {
  height: fit-content;
  min-height: 300px;
  padding: 2rem 0;

  .title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 2rem;
    text-align: center;
  }

  &-parent {
    height: 100%;
    display: flex;

    .item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      font-weight: 500;

      .c-icon {
        color: red(75);
        font-size: 6rem;
        margin-bottom: 25px;
      }
    }
  }
}

.bull-image-banner {
  margin: 5rem 0 3rem;

  .image {
    width: 100% !important;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
      min-height: 400px !important;
    }
  }
}

@media only screen and (max-width: 860px) {
  .bulls-contact {
    &-parent {
      flex-direction: column;
      gap: 2rem;
    }
  }
}

.site-theme {
  &-color {
    color: red(75);
  }

  &-bg-color {
    background-color: red(75);
  }
}