.years {
    margin-bottom: 10px;

    .year-button {
        color: dark-neutral(0) !important;
        background-color: #f0f0f0;
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        margin-right: 5px;
        cursor: pointer;

        &-active {
            border: 1px solid;
            background-color: #fff;
        }

        &:hover {
            background-color: #e0e0e0;
        }
    }
}

.content {
    .item {

        display: none;
        padding: 10px;
        border-radius: 5px;

        &.active {

            display: block;
        }
    }
}