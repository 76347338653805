.project-application {
    .col {
        padding: 0;
    }

    &_form {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &.double {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 1rem;
        }

        &_group {
            border: 1px solid red(75);
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            height: 56px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            input {
                height: 100%;
                width: 100%;
                font-size: 21px;
                line-height: 56px;
                border: none !important;
                box-sizing: border-box;
                box-shadow: none !important;
                outline: none !important;
                background-color: transparent;
                padding: 0 10px !important;
            }


            &.file {
                background-color: red(75);
                color: light-neutral(0);

                label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 25px;
                    line-height: 25px;
                    cursor: pointer;

                    span {
                        font-size: 18px;
                        margin-left: 5px;
                    }

                    .c-icon {
                        font-size: 2rem;
                    }
                }

            }

            &.submit {
                button {
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    background-color: #707070;
                    font-size: 25px;
                    color: light-neutral(0);
                }
            }

            .error__message {
                position: absolute;
                bottom: -70px;
                right: 0;
                background-color: red(400);
                font-size: 18px;
                color: light-neutral(900);
                padding: 5px 15px;
                border-top-left-radius: 20px;
                border-top-right-radius: 3px;
                opacity: 0;
                color: light-neutral(0) !important;
                transition: all, 0.4s ease-in-out;

                &--active {
                    bottom: 0;
                    opacity: 1;
                }
            }
        }
    }
}

// max-width 1440px
@media #{$media-xl} {}

// max-width 1200px
@media #{$media-lg} {}

// max-width 1024px
@media #{$media-md} {}

// max-width 768px
@media #{$media-sm} {
    .project-application {
        &_form {
            &_group {
                &.file {
                    label {
                        font-size: 18px;

                        .c-icon {
                            font-size: 1.5rem;
                        }
                    }
                }
            }

            &.double {
                grid-template-columns: auto !important;
            }
        }
    }
}

// max-width 480px
@media #{$media-xs} {}

// max-width 360px
@media #{$media-es} {}