.breadcrumb-parent {
  padding: 0 0 3rem 0;

  .bread-crumbs {
    display: flex;
    gap: 10px;

    &-item {
      text-transform: capitalize;
      color: dark-neutral(70);
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1px;
      margin-bottom: 1rem;

      &::after {
        content: '>';
        padding-left: 10px;
      }

      &:last-child {
        &::after {
          content: '';
          pointer-events: none;
        }
      }

      &:hover {
        color: dark-neutral(0);
        transition: all, .4s;
      }
    }

    // &-item:first-child::before {
    // content: '🏠';
    // content: '🏠︎';
    // }
    &-item:last-child {
      pointer-events: none;
    }
  }

  .title {
    font-size: 25px;
    text-transform: capitalize;
    line-height: 1.2em;
    letter-spacing: 0.015em;
    font-weight: 500;
    color: #734938;
  }
}

@media only screen and (max-width: 860px) {
  .breadcrumb-parent {
    .title {
      font-size: 25px;
    }
  }
}

// specific css
.imageRadius img {
  width: 400px;
  height: 300px;
  object-fit: cover;
  border-radius: 0 0 30px 30px;
}

.pageDetailComponent>.col:first-child {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

// max-width 1440px
@media #{$media-xl} {}

// max-width 1200px
@media #{$media-lg} {}

// max-width 1024px
@media #{$media-md} {
  .pageDetailComponent {
    flex-direction: column-reverse;

    .imageRadius {
      img {
        width: 100%;
      }
    }
  }
}

// max-width 768px
@media #{$media-sm} {}

// max-width 480px
@media #{$media-xs} {}

// max-width 360px
@media #{$media-es} {}