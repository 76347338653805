.careers {
  margin: 25px 0;
  &__form {
    &_group {
      position: relative;
      margin-bottom: 1rem;
      overflow: hidden;

      &:last-child {
        border: none !important;
      }

      input {
        width: 100%;
        font-size: 21px;
        line-height: 97px;
        border: none !important;
        box-sizing: border-box;
        box-shadow: none !important;
        outline: none !important;
        background-color: transparent;
        padding: 0 28px !important;
        border: 1px solid #d1a998 !important;
        border-radius: 10px;
      }

      .error__message {
        position: absolute;
        bottom: -70px;
        right: 0;
        background-color: red(400);
        font-size: 18px;
        color: light-neutral(900);
        padding: 5px 15px;
        border-top-left-radius: 20px;
        border-top-right-radius: 3px;
        opacity: 0;
        color: light-neutral(0) !important;
        transition: all, 0.4s ease-in-out;

        &--active {
          bottom: 0;
          opacity: 1;
        }
      }

      .send__group {
        position: relative;
        height: 97px;
        font-size: 25px !important;
        line-height: 80px;

        button[type='submit'] {
          width: 100%;
          height: 100%;
          border: none;
          background: #707070 !important;
          outline: none !important;
          box-shadow: none !important;
          position: absolute;
          right: 0;
          overflow: hidden;
          transition: all, 0.4s;
          border-radius: 10px;

          .button-text {
            box-sizing: border-box;
            font-size: 30px;
            color: light-neutral(0);
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 30px;

            .c-icon {
              margin: 0;
            }
          }

          &:hover {
            background-color: #d1a998 !important;
          }
        }
      }

      .upload {
        label {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          line-height: 30px;
          cursor: pointer;

          span {
            font-size: 18px;
            margin-left: 5px;
          }

          .c-icon {
            font-size: 2rem;
          }
        }
      }

      &.bottom {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        font: normal normal normal 90px/110px PP Neue Machina;
        padding: 0;

        .title {
          flex-shrink: 0;
          white-space: nowrap;
          overflow: hidden;
          position: relative;

          &--v2 {
            color: primary(300);
          }
        }
      }

      &.cv {
        color: light-neutral(0);
        padding: 28px 0;
        background-color: red(75);
        border-radius: 10px;
      }
    }
  }
}

.last-message {
  font-size: 2rem;
}

@media #{$media-md} {
  .careers {
    &__form {
      &_group {
        input[type='text'],
        input[type='tel'] {
          font-size: 20px;
          padding: 5px;
          line-height: 25px;
        }

        input[type='text'],
        input[type='tel'],
        .col {
          padding: 15px !important;
        }

        .title {
          line-height: 25px;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .careers {
    &__form {
      &_group {
        padding: 5px 0;

        .error__message {
          font-size: 16px;
        }

        .col {
          padding: 15px !important;
        }

        .title {
          line-height: 50px;
          font-size: 10vw;
        }

        .send__group {
          height: 60px;
          font-size: 25px;
          line-height: 30px;

          button[type='submit'] {
            .button-text {
              padding: 0;
              font-size: 25px;
              line-height: 40px;
            }
          }

          &--success {
            button[type='submit'] {
              .button-text {
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
  }
}
