.footer {
  width: 100%;
  overflow: hidden;
  margin-top: 4rem;

  &-in {
    border-top: 1px solid #ccc;
    padding: 37.5px 0 0 0;
  }

  &-left-part {
    padding: 1rem;

    a {
      display: block;
      text-align: center;
    }

    .c-icon-list {
      display: flex;
      align-items: center;

      a {
        display: flex;
        width: 29px;
        height: 29px;
        border-radius: 100%;
        border: 1px solid dark-neutral(70);
        color: dark-neutral(70);
        align-items: center;
        justify-content: center;
        margin: 0 9px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .footer-social-usename {
        margin-left: 5px;
        color: dark-neutral(70);
        font-weight: 700;
      }
    }
  }

  &-menu {
    color: dark-neutral(60);
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: space-around;
    gap: 2rem;
    font-weight: 600;
    width: 100%;

    li.hasSubMenu>a {
      pointer-events: none;
    }

    >li>a:first-child {
      text-transform: uppercase;
      text-wrap: nowrap;
    }

    &-submenu {
      padding-left: 0 !important;

      >li {
        list-style-type: none;
      }
    }

    >li {
      a {
        color: dark-neutral(70);
        font-weight: 700;
      }

      ul {
        li {
          a {
            font-weight: 400;

            &:hover {
              color: dark-neutral(0);
            }
          }
        }
      }
    }
  }

  &-bottom-part {
    margin: 1rem 0 2rem 0;
    border-top: 1px solid;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: dark-neutral(70);
    font-size: 12px;

    &-menu {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      justify-content: space-between;
      align-items: center;

      li {
        margin: 0;

        &::after {
          content: "/";
          margin: 0 5px;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.lang-switcher {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
  width: 80px;

  .inner {
    button {
      padding: 5px;
      background-color: light-neutral(0);
      border: none;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      font-size: 21px;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 2px;
      cursor: pointer;
      color: dark-neutral(0) !important;

      img {
        width: 30px;
        border-radius: 2px;
      }
    }
  }
}

// max-width 1440px
@media #{$media-xl} {
  .footer {
    &-left-part {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

// max-width 1200px
@media #{$media-lg} {}

// max-width 1024px
@media #{$media-md} {}

// max-width 768px
@media #{$media-sm} {}

// max-width 480px
@media #{$media-xs} {}

// max-width 360px
@media #{$media-es} {}

@media only screen and (max-width: 860px) {
  .footer {
    .row {
      display: flex;
      flex-direction: column;
      padding-bottom: 2rem;
    }

    &-menu {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0;
    }

    &-bottom-part {
      flex-direction: column-reverse;
      gap: 1rem;
      justify-content: center;
      align-items: center;

      &-menu {
        padding: 0;
      }
    }
  }
}