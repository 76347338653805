.brands {
  padding: 4rem 0;

  .container {
    .title {
      font-size: 50px;
      color: red(75);
      border-bottom: 1px solid red(75);
      margin-bottom: 2rem;
      display: inline-block;
      padding-right: 2rem;
      font-weight: 700;
    }

    .brands-parent {
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      grid-gap: 3rem;

      .logo {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 3px;

        img {
          width: 250px;
          height: 150px;
          object-fit: contain;
          background-color: #f2f2f2;
        }
      }
    }
  }
}

// max-width 1440px
@media #{$media-xl} {}

// max-width 1200px
@media #{$media-lg} {}

// max-width 1024px
@media #{$media-md} {}

// max-width 768px
@media #{$media-sm} {
  .brands {
    .container {
      .brands-parent {
        grid-template-columns: auto auto;
      }
    }
  }
}

// max-width 480px
@media #{$media-xs} {}

// max-width 360px
@media #{$media-es} {}
